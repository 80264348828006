import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Dataclasses were added to Python 3.7. They are like regular classes but have some essential functions implemented. Because dataclasses are a decorator, you can quickly create a class, for example `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`from dataclasses import dataclass

@dataclass
class Person:
    name: str
    age: int

`}</code></pre>
    <p>{`We can then create Bob the Builder with:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`
bobthebuilder = Person(name="Bob the Builder", age=33)

print(bobthebuilder)
# Prints: Person(name="Bob the Builder", age=33)

`}</code></pre>
    <p>{`One great thing about dataclasses is that you can create one and use the class attributes if you want a specific thing. For example, let's say that we want to know Bob's age`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`print(bobthebuilder.age)
# Prints: 33
`}</code></pre>
    <p>{`Another brilliant thing about dataclasses is that you don't need to implement a class by hand since, by default, dataclasses implement the methods `}<inlineCode parentName="p">{`__init__`}</inlineCode>{`, `}<inlineCode parentName="p">{`__repr__`}</inlineCode>{` and `}<inlineCode parentName="p">{`__eq__`}</inlineCode>{` under the hood. `}</p>
    <p>{`You can also enable/disable this behaviour by passing arguments to the decorator. Refer to the `}<a parentName="p" {...{
        "href": "https://docs.python.org/3/library/dataclasses.html#module-contents"
      }}>{`dataclass documentation`}</a>{` for more information.`}</p>
    <h2 {...{
      "id": "using-dataclasses-instead-of-dictionaries",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#using-dataclasses-instead-of-dictionaries",
        "aria-label": "using dataclasses instead of dictionaries permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using dataclasses instead of dictionaries`}</h2>
    <p>{`Let's assume that you are interacting with an API. When you query an endpoint, you might get a dictionary back (usually JSON is turned into a dictionary in Python). `}</p>
    <p>{`Providing that the response is always the same, you could use the dictionary to get values from the payload. Or create a Payload dataclass and instantiate the dataclass from the dictionary.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`
from dataclasses import dataclass
import requests

@dataclass
class Book:
    author: str
    title: str
    isbn: str
    pages: int
    publisher: str
    rating: int


result = requests.get("http://fake-book-api/the-pragmatic-programmer")
payload = result.json()

"""
Payload is: 
{
    "author": "David Thomas, Andrew Hunt",
    "title": "The Pragmatic Programmer",
    "isbn": 0135957052,
    "pages": 352,
    "publisher": "Addison-Wesley Professional",
    "rating": 5
}
"""
`}</code></pre>
    <p>{`Let's assume that you want to get both the author and rating from your payload. You can easily do such with:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`author = payload.get("author")
rating = payload.get("rating")
`}</code></pre>
    <p>{`Alternatively, you can use the dataclass payload like such`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`book = Book(
        author=payload.get("author"),
        title=payload.get("title"),
        isbn=payload.get("isbn"),
        pages=payload.get("pages"),
        publisher=payload.get("publisher"),
        rating=payload.get("rating")
    )

print(book.author)
# Prints: David Thomas, Andrew Hunt
`}</code></pre>
    <h3 {...{
      "id": "reason-to-use-dataclasses",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#reason-to-use-dataclasses",
        "aria-label": "reason to use dataclasses permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Reason to use dataclasses`}</h3>
    <p>{`Perhaps using a dictionary is enough when you are dealing with an API that returns you the same structure.  But what if you are interacting with more than one API. Or if your API returns a different payload depending on the endpoint, you are querying?`}</p>
    <p>{`Let's assume that you are now using these two APIs because one has books that the other doesn't:`}</p>
    <ul>
      <li parentName="ul">{`http://fake-book-api/`}</li>
      <li parentName="ul">{`http://awesome-fake-book-api/`}</li>
    </ul>
    <p>{`We have seen the payload that `}<inlineCode parentName="p">{`fake-book-api`}</inlineCode>{` returns, but just for reference:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "author": "David Thomas, Andrew Hunt",
    "title": "The Pragmatic Programmer",
    "isbn": "0135957052",
    "pages": 352,
    "publisher": "Addison-Wesley Professional",
    "rating": 5
}

`}</code></pre>
    <p>{`The new api `}<inlineCode parentName="p">{`awesome-fake-book-api`}</inlineCode>{` returns the payload like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "author-name": "Robert C. Martin",
    "title": "Clean Code: A Handbook of Agile Software Craftsmanship",
    "isbn-10": "9780132350884",
    "isbn-13": "978-0132350884",
    "pages": 464,
    "weight": "1.54 pounds",
    "ratings": {
        "total": 4,
        "reviews": [
            {"username": "Bob", "rating": 4, "comment": "Is good!"}
        ]
    }
}
`}</code></pre>
    <p>{`This new API doesn't return information about the`}<inlineCode parentName="p">{`publisher`}</inlineCode>{`. The `}<inlineCode parentName="p">{`author`}</inlineCode>{` key is called `}<inlineCode parentName="p">{`author-name`}</inlineCode>{`. The payload also contains values for  both the `}<inlineCode parentName="p">{`isbn-10`}</inlineCode>{` and `}<inlineCode parentName="p">{`isbn-13`}</inlineCode>{`. Finally, ratings are now a dictionary that contains `}<inlineCode parentName="p">{`total`}</inlineCode>{` and `}<inlineCode parentName="p">{`reviews`}</inlineCode>{`.`}</p>
    <p>{`Still, you can refactor the Book dataclass to handle both APIS. For example: `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`book = Book(
    author=payload.get("author") or payload.get("author-name"),
    title=payload.get("title"),
    isbn=payload.get("isbn"),
    pages=payload.get("pages"),
    publisher=payload.get("publisher", "Unknown"),
    rating=payload.get("rating") or payload.get("ratings", {}).get("total")
)

print(book.author)
# Prints: David Thomas, Andrew Hunt or Robert C. Martin
`}</code></pre>
    <p>{`Okay, that looks a bit ugly, but at least you can keep the dictionary juggling in a single place, and in the rest of your code, you can access the `}<inlineCode parentName="p">{`Book`}</inlineCode>{` attributes.`}</p>
    <h2 {...{
      "id": "constructing-a-dataclass-from-a-dictionary",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#constructing-a-dataclass-from-a-dictionary",
        "aria-label": "constructing a dataclass from a dictionary permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Constructing a dataclass from a dictionary`}</h2>
    <p>{`Let's be honest. Constructing the Book dataclass as we did before is ugly, but it does the trick. Ideally, we should pass the payload to the dataclass, and the attributes would be filled automatically.`}</p>
    <p>{`But if you try to pass the payload into the dataclass you get a TypeError exception.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`payload = {
    "author": "David Thomas, Andrew Hunt",
    "title": "The Pragmatic Programmer",
    "isbn": 135957052,
    "pages": 352,
    "publisher": "Addison-Wesley Professional",
    "rating": 5
}

book = Book(payload)
# Raises: TypeError: __init__() missing 5 required positional arguments: 'title', 'isbn', 'pages', 'publisher', and 'ratings'
`}</code></pre>
    <p>{`This exception isn't surprising. If we pass the payload to the dataclass, then the whole dictionary is added to the `}<inlineCode parentName="p">{`author`}</inlineCode>{` attribute.`}</p>
    <p>{`If we want to construct our dataclass from a dictionary, we need to add a class method and use that method instead. Let's do that now and add a `}<inlineCode parentName="p">{`from_payload`}</inlineCode>{` method to our dataclass.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`
@dataclass
class Book:
    author: str
    title: str
    isbn: str
    pages: int
    publisher: str
    rating: int
    
    @classmethod
    def from_payload(cls, payload: dict):
        """Construct the Book class from a dictionary."""
        author=payload.get("author") or payload.get("author-name")
        title=payload.get("title")
        isbn=payload.get("isbn") or payload.get("isbn-10")
        pages=payload.get("pages"),
        publisher=payload.get("publisher", "Unknown"),
        rating=payload.get("rating") or payload.get("ratings", {}).get("total")
        
        return cls(
            author=author,
            title=title,
            isbn=isbn,
            pages=pages,
            publisher=publisher,
            rating=rating
        )
`}</code></pre>
    <p>{`The brilliant thing is that we can call the `}<inlineCode parentName="p">{`from_payload`}</inlineCode>{` method to construct our Book. The logic is part of the dataclass, which will make our code cleaner. For example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`payload = {
    "author": "David Thomas, Andrew Hunt",
    "title": "The Pragmatic Programmer",
    "isbn": 135957052,
    "pages": 352,
    "publisher": "Addison-Wesley Professional",
    "rating": 5
}

book = Book.from_payload(payload)

print(book.title)
# Prints: The Pragmatic Programmer

`}</code></pre>
    <p>{`Another great thing about dataclasses is that you can use the `}<inlineCode parentName="p">{`dataclasses.asdict`}</inlineCode>{` method to get a dictionary back from a dataclass. For example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`import dataclasses

payload = {
    "author": "David Thomas, Andrew Hunt",
    "title": "The Pragmatic Programmer",
    "isbn": 135957052,
    "pages": 352,
    "publisher": "Addison-Wesley Professional",
    "rating": 5
}

book = Book.from_payload(payload)

print(dataclasses.asdict(book))
# Prints: {'author': 'David Thomas, Andrew Hunt', 'title': 'The Pragmatic Programmer', 'isbn': 135957052, 'pages': 352, 'publisher': 'Addison-Wesley Professional', 'rating': 5}

dataclasses.asdict(book) == payload # This is True

`}</code></pre>
    <h3 {...{
      "id": "real-example",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#real-example",
        "aria-label": "real example permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Real Example`}</h3>
    <p>{`I have recently added a `}<a parentName="p" {...{
        "href": "https://docs.opsdroid.dev/en/stable/connectors/gitlab.html"
      }}>{`Gitlab Connector`}</a>{` to `}<a parentName="p" {...{
        "href": "https://opsdroid.dev/"
      }}>{`Opsdroid`}</a>{`. Opsdroid allows you to emit events based on actions - in GitLab case; these actions come from webhooks.`}</p>
    <p>{`GitLab webhooks have different structures depending on the action. For example, the payload might have three keys for a username - `}<inlineCode parentName="p">{`user`}</inlineCode>{`, `}<inlineCode parentName="p">{`username`}</inlineCode>{`, `}<inlineCode parentName="p">{`user_username`}</inlineCode>{`.`}</p>
    <p>{`Instead of dealing with the different payloads for each event that Opsdroid builds for the GitLab connector, I've created a `}<inlineCode parentName="p">{`Payload`}</inlineCode>{` dataclass and kept the logic inside a `}<inlineCode parentName="p">{`from_dict`}</inlineCode>{` method. `}</p>
    <p>{`Would you please look at `}<a parentName="p" {...{
        "href": "https://github.com/opsdroid/opsdroid/blob/master/opsdroid/connector/gitlab/connector.py#L34-L91"
      }}>{`the code`}</a>{` for reference and for a working example of what I have shown in here?`}</p>
    <h2 {...{
      "id": "enforcing-types",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#enforcing-types",
        "aria-label": "enforcing types permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Enforcing types`}</h2>
    <p>{`When creating a dataclass, you specify the attribute type, but the dataclass itself doesn't enforce these types. Let's grab our Book example, you could totally pass any type to any of the attributes, and all would be well.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`
@dataclass
class Book:
    author: str
    title: str
    isbn: str
    pages: int
    publisher: str
    rating: int

book = Book(author="Bob", title=12345, isbn=1234, pages="a million!", publisher=None, rating="Magnific")

print(book.title)
# Prints: 12345
`}</code></pre>
    <p>{`As you can see, typing isn't enforced. This is okay, providing that you are 100% certain what types you are putting in the dataclass. You can also implement a typing check in the `}<inlineCode parentName="p">{`from_payload`}</inlineCode>{` to ensure that the types are correct, although if someone builds `}<inlineCode parentName="p">{`Book`}</inlineCode>{` directly, that person could bypass your type checking.`}</p>
    <h3 {...{
      "id": "whats-the-point-of-enforcing-types",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#whats-the-point-of-enforcing-types",
        "aria-label": "whats the point of enforcing types permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What's the point of enforcing types`}</h3>
    <p>{`You might wonder why you would want to enforce typing in your dataclass. Typing helps you write better and less buggy code because your editor will warn you about issues before you even spot them.`}</p>
    <p>{`It's also a great way to handle user input. For example, you could build a Payload dataclass to handle input from an API that users can interact.  I did precisely this on a feature that I implemented for Opsdroid where users can submit a `}<a parentName="p" {...{
        "href": "https://github.com/FabioRosado/opsdroid/blob/fr/command-center/opsdroid/web.py#L383-L416"
      }}>{`patch request`}</a>{` to the API to update their configuration on the fly.`}</p>
    <h3 {...{
      "id": "the-__post_init__-method",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#the-__post_init__-method",
        "aria-label": "the __post_init__ method permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The `}<inlineCode parentName="h3">{`__post_init__`}</inlineCode>{` method`}</h3>
    <p>{`To add type checking, we need to use the `}<inlineCode parentName="p">{`__post_init__()`}</inlineCode>{` method from the dataclass. If your dataclass generates a `}<inlineCode parentName="p">{`__init__()`}</inlineCode>{` method under the hood, then `}<inlineCode parentName="p">{`__post_init__()`}</inlineCode>{` will be called.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`__post_init__`}</inlineCode>{` allows you to do a few things, such as initializing field values that depend on one or more fields or that you need to add a default value. Let's assume that you want to create a dataclass with default values.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`import dataclasses

@dataclasses.dataclass
class Book:
    author: str
    title: str
    isbn: str
    pages: int
    publisher: str
    rating: dict = dataclasses.field(default_factory=dict)
    
    def __post_init__(self):
        if not self.rating:
            self.rating = {"total": 0, "reviews": []}

book = Book("Robert C. Martin", "Clean Code", "9780132350884", 464, None)

Print(book)
# Prints: Book(author='Robert C. Martin', title='Clean Code', isbn='9780132350884', pages=464, publisher=None, rating={'total': 0, 'reviews': []})

`}</code></pre>
    <p>{`You need to set `}<inlineCode parentName="p">{`dataclasses.field`}</inlineCode>{` because dictionaries are mutable, and you can't use a mutable default value.`}</p>
    <p>{`You can also see that with the code in the `}<inlineCode parentName="p">{`__post_init__`}</inlineCode>{`, we only set `}<inlineCode parentName="p">{`self.rating`}</inlineCode>{` if this value doesn't exist. If the user provides the value then we aren't setting the default value of `}<inlineCode parentName="p">{`{"total": 0, "reviews": []}`}</inlineCode>{`.`}</p>
    <p>{`There is much more than I can say about dataclasses fields, but I will leave that topic for another article.`}</p>
    <h3 {...{
      "id": "implementing-type-checking",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#implementing-type-checking",
        "aria-label": "implementing type checking permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Implementing type checking`}</h3>
    <p>{`To implement type checking for our Book dataclass we need to play with dunder methods. We need to look into `}<inlineCode parentName="p">{`__annotations__`}</inlineCode>{` and also `}<inlineCode parentName="p">{`__dict__`}</inlineCode>{`. For reference, let's pick up the previous example and see what `}<inlineCode parentName="p">{`__annotations__`}</inlineCode>{` and `}<inlineCode parentName="p">{`__dict__`}</inlineCode>{` gives us.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`
book = Book("Robert C. Martin", "Clean Code", "9780132350884", 464, None)

print(book.__annotations__)

# Prints: {'author': <class 'str'>, 'title': <class 'str'>, 'isbn': <class 'str'>, 'pages': <class 'int'>, 'publisher': <class 'str'>, 'rating': <class 'dict'>}

print(book.__dict__)
# Prints: {'author': 'Robert C. Martin', 'title': 'Clean Code', 'isbn': '9780132350884', 'pages': 464, 'publisher': None, 'rating': {'total': 0, 'reviews': []}}

`}</code></pre>
    <p>{`Since we have both the key and the expected type for each key in `}<inlineCode parentName="p">{`__annotations__`}</inlineCode>{`, we can use that to check if the provided key is the expected type or not. Let's now build our `}<inlineCode parentName="p">{`__post__init()`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`
import dataclasses

@dataclasses.dataclass
class Book:
    author: str
    title: str
    isbn: str
    pages: int
    publisher: str
    rating: dict = dataclasses.field(default_factory=dict)
    
    def __post_init__(self):
        if not self.rating:
            self.rating = {"total": 0, "reviews": []}
        
        for name, field_type in self.__annotations__.items():
            provided_key = self.__dict__[name]
            
            if not isinstance(provided_key, field_type):
                raise TypeError(
                    f"The field '{name}' is of type '{type(provided_key)}', but "
                    f"should be of type '{field_type}' instead."
                )
`}</code></pre>
    <p>{`For reference, our for loop will go through each attribute (author, title, isbn, pages, publisher, rating) and expected type. Then we confirm that the user's value with `}<inlineCode parentName="p">{`self.__dict__[name]`}</inlineCode>{` is of the expected type. If not, we raise a `}<inlineCode parentName="p">{`TypeError`}</inlineCode>{`.`}</p>
    <p>{`Now, if we create a book instance with the wrong type, we get the `}<inlineCode parentName="p">{`TypeError`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`book = Book("Robert C. Martin", "Clean Code", 9780132350884, 464, None)

# Raises TypeError: The field 'isbn' is of type '<class 'int'>', but should be of type '<class 'str'>' instead.

`}</code></pre>
    <p>{`You probably noticed that the code failed on the first type check. But we actually have two wrong types for `}<inlineCode parentName="p">{`isbn`}</inlineCode>{` and `}<inlineCode parentName="p">{`publisher`}</inlineCode>{`. If you wish you could add some logic to let the for loop go through all the fields and raise the `}<inlineCode parentName="p">{`TypeError`}</inlineCode>{` at the end.`}</p>
    <p>{`Note: You can only use this method for exact single types. If you are checking for Optional or Union types, then the check `}<inlineCode parentName="p">{`isinstance`}</inlineCode>{` will raise a `}<inlineCode parentName="p">{`TypeError: Subscripted generics cannot be used with class and instance checks`}</inlineCode></p>
    <p>{`For example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`import dataclasses
from typing import Optional

@dataclasses.dataclass
class Book:
    author: str
    title: str
    isbn: str
    pages: int
    publisher: Optional[str] # This breaks our type checking
    rating: dict = dataclasses.field(default_factory=dict)
    
    def __post_init__(self):
        if not self.rating:
            self.rating = {"total": 0, "reviews": []}
        
        for name, field_type in self.__annotations__.items():
            provided_key = self.__dict__[name]
            
            if not isinstance(provided_key, field_type):
                raise TypeError(
                    f"The field '{name}' is of type '{type(provided_key)}', but "
                    f"should be of type '{field_type}' instead."
                )
`}</code></pre>
    <p>{`So how do we fix this? If we want to set `}<inlineCode parentName="p">{`publisher`}</inlineCode>{` as optional? We can do more dunder magic!`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`import dataclasses
from typing import Optional

@dataclasses.dataclass
class Book:
    author: str
    title: str
    isbn: str
    pages: int
    publisher: Optional[str]
    rating: dict = dataclasses.field(default_factory=dict)
    
    def __post_init__(self):
        if not self.rating:
            self.rating = {"total": 0, "reviews": []}
        
        for name, field_type in self.__annotations__.items():
            provided_key = self.__dict__[name]
            
            try:
                type_matches = isinstance(provided_key, field_type)
            except TypeError:
                type_matches = isinstance(provided_key, field_type.__args__)
            
            if not type_matches:
                raise TypeError(
                    f"The field '{name}' is of type '{type(provided_key)}', but "
                    f"should be of type '{field_type}' instead."
                )

# Now this works!
book = Book("Robert C. Martin", "Clean Code", 9780132350884, 464, None)

`}</code></pre>
    <h2 {...{
      "id": "thats-all-folks",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#thats-all-folks",
        "aria-label": "thats all folks permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`That's all folks`}</h2>
    <p>{`I hope this long article helped you have a better understanding of dataclasses and how to use them. I enjoy using dataclasses daily, especially when interacting with APIs, since dataclasses allow me to make my code cleaner without the need to implement an entire class by hand.`}</p>
    <p>{`Please let me know what you think and your use-case for dataclasses!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      